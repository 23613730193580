import cn from 'classnames';
import React from 'react';
import arweave from '../../dapp/contracts/arweave.json';
import contractAddress from '../../dapp/contracts/contract-address.json';
import ipfs from '../../dapp/contracts/ipfs.json';
import { HTAX_CREATOR_ADDRESS } from '../../dapp/utils/HTAX/constants';
import styles from './MintAsset.module.sass';

function MintAsset({ mintAsset }) {
  return (
    <div>
      <button
        className={cn('button', styles.button)}
        onClick={() => {
          mintAsset(
            arweave.HarbergerAsset,
            ipfs.HarbergerAsset,
            HTAX_CREATOR_ADDRESS,
            contractAddress.PaymentSplitter
          );
        }}
      >
        Mint Asset
      </button>
    </div>
  );
}

export default MintAsset;
