import cn from 'classnames';
import React, { Component } from 'react';
import styles from './Hero.module.sass';
import Item from './Item';

const items = [
  {
    question: `What is the NFT?`,
    answer: `This historic <b style="color: #c074ff">1 of 1</b> NFT is the very first of its kind song (and video) to be released via Harberger Taxes. The track also remixes BeetsDAO’s original <b style="color: #c074ff">Enigma LP 17</b> from the EulerBeats collection.
    <br/><br/>
    <a href="https://eulerbeats.com" target="_blank" style="color: #c074ff">EulerBeats</a> is the first, fully on-chain, generative music project that introduced the concept of a bonding curve to distribute music-based royalties.`,
  },
  {
    question: `Who is the creator?`,
    answer: `<a href="https://twitter.com/songadaymann" target="_blank" style="color: #c074ff">Jonathan Mann</a> is an American singer-songwriter, best known for creating and publishing a new song every day since January 2009 (before the inception of Bitcoin & Ethereum). Visit <a href="https://songaday.world" target="_blank" style="color: #c074ff">songaday.world</a> to discover more of his work.`,
  },
  {
    question: `Who is the curator?`,
    answer: `<a href="https://twitter.com/BeetsDAO" target="_blank" style="color: #c074ff">BeetsDAO</a> is a global NFT collective first formed in the EulerBeats community by 58 collaborators who met on a discord server, with a shared passion for music and the long-term power of NFTs. Visit <a href="https://beetsdao.com" target="_blank" style="color: #c074ff">beetsdao.com</a> to learn more about the DAO.
    <br/><br/>
    This project was developed and built by members of BeetsDAO.`,
  },
  {
    question: `How does the auction work?`,
    answer: `The auction is hosted through an extension of the Zora protocol (<a href="https://docs.zora.co/docs/developer-tools/zdk/auction-house" target="_blank" style="color: #c074ff">Auction House</a>). It will run for a period of <b style="color: #c074ff">48 hours</b> and the reserve price will be set at <b style="color: #c074ff">1 ETH</b>.
    <br/><br/>
    Every time you place a bid, it must be <b style="color: #c074ff">5%</b> higher than the previously set bid. Bids are placed in ETH (not WETH) so if you are outbid, you will immediately be issued a refund for your bid. If a bid is placed within the final few minutes, the clock will continue to extend.`,
  },
  {
    question: `What happens after I win the auction?`,
    answer: `Once the auction concludes, and the asset has been transferred by the protocol, the new owner will have <b style="color: #c074ff">24 hours</b> to first list the asset for sale and then deposit the necessary taxes based on the listing price that was just set. When taxes are pre-paid, the clock will continue to extend, preventing a foreclosure on the asset from occurring.`,
  },
  {
    question: `Can I sell the NFT on other marketplaces?`,
    answer: `No, the NFT can ONLY be listed and sold through this marketplace. Furthermore, the asset can ONLY be transferred based on one of <b style="color: #c074ff">3</b> circumstances:
    <br/>
    <ul>
      <li>- by the contract itself (when a sale occurs)</li>
      <li>- by the auction house (when the auction begins/ends)</li>
      <li>- by the creator or curator (when a foreclosure is active)</li>
    </ul>
    `,
  },
  {
    question: `What is a foreclosure?`,
    answer: `A foreclosure occurs when the owner of the asset fails to deposit (pre-pay) enough taxes to prevent the timer from expiring. The creator or curator can then rightfully reclaim the asset at any time. A new auction will then commence.`,
  },
  {
    question: `Why do I always need to have the asset listed for sale?`,
    answer: `The Harberger taxation model requires the owner of the asset to always have the asset listed for sale. It's important to set a sale price at which you are comfortable with letting go of the asset. Price it too low and someone will likely buy it out from under you. Price it too high and risk the asset never selling, as well as having to deposit a higher amount in taxes to extend a foreclosure.`,
  },
  {
    question: `What is the point of depositing taxes?`,
    answer: `Depositing taxes is the only way to extend ownership on the asset. The annual tax rate is <b style="color: #c074ff">36.5%</b> of the sales price, which equates to a <b style="color: #c074ff">0.1%</b> daily tax rate. Every deposit must at least meet the minimum tax fee, which is always <b style="color: #c074ff">10%</b> of the sales price. The owner can always choose to deposit more in order to reduce future transactions and save gas fees.`,
  },
  {
    question: `How does the tax calculator work?`,
    answer: `Simply select a date from the calendar to view how much taxes would need to be deposited in order to extend a foreclosure to the selected date. The tax rate will be determined by the currently set listing price, and takes into account taxes that have already been pre-paid.`,
  },
  {
    question: `What happens when I change the listing price?`,
    answer: `The foreclosure date will update in accordance to the new listing price. The length of time the tax is pre-paid will increase if the sales price decreases, and decrease if the sales price increases. The daily tax rate updates to correlate with the new listing price. There is no limit on how many times the asset price can be updated, but it is important to take these factors into consideration before changing the price.`
  },
  {
    question: `What happens when a sale occurs?`,
    answer: `If there is any time remaining on the clock, a refund of overpaid taxes is first issued. There is a <b style="color: #c074ff">10%</b> royalty on every secondary sale, so <b style="color: #c074ff">90%</b> of the sale will be transfered to the current owner while the remaining funds will get distributed to the creator(s). The asset state will then reset, giving the new owner a fresh <b style="color: #c074ff">24-hour</b> grace period to set a listing price and deposit their desired taxes.`,
  },
  {
    question: `Where do the taxes go?`,
    answer: `Taxes are split <b style="color: #c074ff">50/50</b> between the creator and curator.`,
  },
  {
    question: `Where can I learn more about Harberger Taxes?`,
    answer: `This project was heaviliy influenced by the work of Simon de la Rouviere (<a href="https://thisartworkisalwaysonsale.com" target="_blank" style="color: #c074ff">ThisArtworkIsAlwaysOnSale</a>), who was one of the first to bring Harberger Taxes to the blockchain.
    <br/><br/>
    Here are some additional resources to learn more about the theory behind this underlying concept:
    <br/>
    <ul>
      <li>
        - <a href="https://vitalik.ca/general/2018/04/20/radical_markets.html" target="_blank" style="color: #c074ff; text-decoration: underline !important">On Radical Markets by Vitalik Buterin</a>
      </li>
      <li>
        - <a href="https://academic.oup.com/jla/article/9/1/51/3572441" target="_blank" style="color: #c074ff; text-decoration: underline !important">Property Is Only Another Name for Monopoly</a>
      </li>
      <li>
        - <a href="https://medium.com/@simondlr/what-is-harberger-tax-where-does-the-blockchain-fit-in-1329046922c6" target="_blank" style="color: #c074ff; text-decoration: underline !important">What is Harberger Tax & Where Does The Blockchain Fit In?</a>
      </li>
    </ul>
    `,
  }
];

items.map(i => i.isVisible = false);

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: items,
    };
  }

  setVisible(index) {
    this.state.questions[index].isVisible = !this.state.questions[index].isVisible;

    this.setState({ questions: this.state.questions })
  };

  toggleAll() {
    this.state.questions = this.state.questions.map(q => {
      q.isVisible = !q.isVisible;
      return q;
    });

    this.setState({ questions: this.state.questions })
  }

  render() {
    return (
      <div className="container any-faq wf-section">
        <div className="any-hero-headline any-left" style={{ marginTop: "200px" }}>FAQ</div>
        <div className="faq-container">
          {items.map((item, index) => (
              <span key={index}>
                  <Item
                      className={styles.item}
                      question={item.question}
                      answer={item.answer}
                      isVisible={item.isVisible}
                      onClickCallback={() => this.setVisible(index)}
                  />
              </span>
          ))}
        </div>
      </div>
    );
  }
}


export default FAQ;
