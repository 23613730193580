import React from 'react';

const Preview = ({ className, question, answer, isVisible, onClickCallback }) => {
  const visibility = isVisible ? '' : 'd-none';
  return (
      <div>
        <div className="faq-p w-dropdown">
          <div className="faq w-dropdown-toggle">
            <div className="toggle-text" onClick={onClickCallback}>
                {question}
            </div>
          </div>
          <nav className={`faq-body w-dropdown-list ${visibility}`}>
            <div className="faq-body-cont">
              <div className="faq-answer" dangerouslySetInnerHTML={{__html: answer}} />
            </div>
          </nav>
        </div>
      </div>

  );
};

export default Preview;
