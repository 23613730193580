import cn from 'classnames';
import React from 'react';
import styles from './TextInput.module.sass';

const TextInput = React.forwardRef(({ className, label, ...props }, ref) => (
  <div className={cn(styles.field, className)}>
    {label && <div className={styles.label}>{label}</div>}
    <div className={styles.wrap}>
      <input ref={ref} className={styles.input} {...props} />
    </div>
  </div>
));

export default TextInput;
