import moment from 'moment-timezone';
import React, { Component } from 'react';
import {
  ETHERSCAN_TX_URI,
  OPEN_SEA_BASE_URI,
} from '../../dapp/utils/constants';
import { convertToEth, minifyHash } from '../../dapp/utils/helpers';
import styles from './AuctionTable.module.sass';

class AuctionTable extends Component {
  constructor(props) {
    super(props);
    this.formatTime = this.formatTime.bind(this);
  }

  formatTime = (unixTime) => {
    if (!unixTime) return;
    const localtz = moment.tz.guess();
    return moment.unix(unixTime).tz(localtz).format('M/DD/YY h:mm A');
  };

  render() {
    const auctionBids = this.props.auctionBids
      .sort((a, b) => a['createdAtTimestamp'] - b['createdAtTimestamp'])
      .reverse();

    return (
      <div>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Created</th>
                <th>From</th>
                <th>Bid</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {auctionBids.map((bid, index) => {
                return (
                  <tr key={index}>
                    <td>{this.formatTime(bid['createdAtTimestamp'])}</td>
                    <td>
                      <a
                        className={styles.link}
                        href={OPEN_SEA_BASE_URI + bid['bidder']['id']}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {minifyHash(bid['bidder']['id'])}
                      </a>
                    </td>
                    <td>
                      <a
                        className={styles.link}
                        href={ETHERSCAN_TX_URI + bid['transactionHash']}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {bid['bidType']}
                      </a>
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      Ξ {parseFloat(convertToEth(bid['amount'].toString()))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AuctionTable;
