import React from 'react';
import styles from './CTA.module.sass';

function CTA() {
  return (
    <div className="container pt-2 pb-3">
      <div className="row">
        <span className={styles.blurb}>
        <h3 className={"mb-4"}>How it Works</h3>
        <b>Brief History: </b>&nbsp;In 1954, Arnold Harberger proposed a simple theory designed to limit the pricing power of private owners holding scarce, valuable and publicly important assets such as art. Harberger Tax was introduced to discourage monopolists from setting unreasonably high prices and thus dampening the flow of economic activity and innovation.
        <br /><br />
          <b>The Mechanics: </b>&nbsp;To combat it, a game theory was introduced to require owners to 1) self-assess the value of their property; 2) pay taxes based on that announced valuation; and 3) sell it to anyone willing to pay the announced price. The net effect? Set an unreasonably high price and pay the tax to keep that right, with taxes flowing back to productivity.
        <br /><br />
        In the blockchain version of this game, we are inspired by Simon de la Rouviere's "This Artwork is Always on Sale". "The Harberger Tax Experiment" is the first song to be sold under the Harberger Tax model, performed by Jonathan Mann as Song #2342, a 1/1 in his Song a Day series.
        </span>
        {/*<div className={`col-lg-4 text-center ${styles.box}`}>*/}
          {/*<img src="/images/content/own-it.svg" alt="Own It" />*/}
          {/*<h2 className="mb-3 mt-3">Buy It</h2>*/}
          {/*<p className={styles.text}>*/}
            {/*Own a piece*/}
            {/*<br /> of history*/}
          {/*</p>*/}
        {/*</div>*/}
        {/*<div className={`col-lg-4 text-center ${styles.box}`}>*/}
          {/*<img src="/images/content/keep-it.svg" alt="Keep It" />*/}
          {/*<h2 className="mb-3 mt-3">List It</h2>*/}
          {/*<p className={styles.text}>*/}
            {/*Set a price at which*/}
            {/*<br /> you are comfortable*/}
            {/*<br /> parting with it*/}
          {/*</p>*/}
        {/*</div>*/}
        {/*<div className={`col-lg-4 text-center ${styles.box}`}>*/}
          {/*<img src="/images/content/sell-it.svg" alt="Sell It" />*/}
          {/*<h2 className="mb-3 mt-3">Extend It</h2>*/}
          {/*<p className={styles.text}>*/}
            {/*Pay the tax for as long*/}
            {/*<br /> as you value it*/}
          {/*</p>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default CTA;
