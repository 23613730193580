import cn from 'classnames';
import React, { useState } from 'react';
import AuctionTable from '../../components/AuctionTable';
import Provenance from '../../components/Provenance';
import { ETHERSCAN_BASE_URI } from '../../dapp/utils/constants';
import { minifyHash } from '../../dapp/utils/helpers';
import Users from '../../screens/Item/Users';
import styles from './Tabs.module.sass';

const navLinks = ['Metadata', 'Provenance', 'Auction'];
const contractAddress = "0x9982e0D8d59d36a729154d99Cf21D384f6D53bCC";

const users = [
  {
    name: 'The Harberger Tax Song',
    position: 'Title',
    avatar: '/image.png',
    twitter: 'https://opensea.io/assets/' + contractAddress + '/1',
  },
  {
    name: 'Jonathan Mann',
    position: 'Creator',
    avatar: '/assets/artists/1.jpg',
    twitter: 'https://twitter.com/songadaymann',
  },
  {
    name: 'BeetsDAO',
    position: 'Curator',
    avatar: '/assets/logos/transparent.png',
    twitter: 'https://twitter.com/beetsDAO',
  },
];

const Tabs = ({ dapp, auctionBids, auction }) => {
  const [activeIndex, setActiveIndex] = useState(() => {
    // If an auction is active, set default tab to "Bids"
    if (auction?.id && auction?.status === 'Active') {
      return 2;
    }
    return 0;
  });
  return (
    <>
      <div className={styles.nav}>
        {navLinks.map((x, index) => (
          <button
            className={cn(
              { [styles.active]: index === activeIndex },
              styles.link
            )}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {activeIndex === 0 && (
        <div>
          <Users className={styles.users} items={users} />
          <p className={styles.contractAddress}>
            Contract Address:{' '}
            <a
              href={ETHERSCAN_BASE_URI + dapp.state.contractAddress}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: "#c074ff" }}
            >
              {minifyHash(dapp.state.contractAddress)}
            </a>
          </p>
          <p style={{ fontSize: "12px" }}>Note: This contract has been reviewed but not formally audited. Caution is advised.</p>
        </div>
      )}
      {activeIndex === 1 && <Provenance />}
      {activeIndex === 2 && <AuctionTable auctionBids={auctionBids} />}
    </>
  );
};

export default Tabs;
