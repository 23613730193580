import React, {useState} from 'react';
import Hero from './Hero';
import './any_webflow/css/normalize.css';
import './any_webflow/css/webflow.css';
import './any_webflow/css/alwaysneveryours.webflow.css';
import FAQNew from "./FAQ";
import {AlwaysNeverTitle} from "./AlwaysNeverTitle";

class HomeNew extends React.Component {
    componentDidMount() {
        window.bodymovin.loadAnimation({
            container: document.getElementById('character-animation'),
            path: 'https://beets-dao-cdn.s3.amazonaws.com/characters-12-28-21.json',
            renderer: 'svg'
        });
    }

    render() {
        return (
            <div>
                <div className="any-hero wf-section">
                    <div className="any-hero-headline-wrap">
                        <div className="any-hero-headline">
                            <AlwaysNeverTitle />
                        </div>
                        <div className="any-hero-subheader">A radical ownership experiment</div>
                    </div>
                    <div id="character-animation" className="any-herographicwrap">
                        <div className="any-timelinecontainer w-row">
                            <div className="any-character-product-2 w-col w-col-3">
                                <img src="images/01-music.svg" loading="lazy" alt="" className="any-product rectangle"/>
                                <img src="images/01B.svg" loading="lazy" alt="" className="any-character"/>
                            </div>
                            <div className="any-character-product w-col w-col-3">
                                <div className="any-product-circle">
                                    <img src="images/02-music.svg" loading="lazy" alt="" className="any-product circle"/>
                                </div>
                                <img src="images/02B.svg" loading="lazy" alt="" className="any-character"/>
                            </div>
                            <div className="any-character-product-2 w-col w-col-3">
                                <div className="any-product-circle">
                                    <img src="images/03-music.svg" loading="lazy" alt="" className="any-product circle"/>
                                </div>
                                <img src="images/03B.svg" loading="lazy" alt="" className="any-character"/>
                            </div>
                            <div className="any-character-product-2 w-col w-col-3">
                                <img src="images/04-music.svg" loading="lazy" alt="" className="any-product rectangle"/>
                                <img src="images/04B.svg" loading="lazy" alt="" className="any-character grandpa"/>
                            </div>
                        </div>
                        <div data-w-id="2cf50e0d-803d-b29e-152a-28615b2db5ce" data-animation-type="lottie" data-src="documents/characters-12-28-21.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="3.962295971910993" data-duration={0}/>
                    </div>
                    <div className="any-hero-info-wrap">
                        <div className="any-hero-headline">
                            <span className="any-hero-headline-subheader">This is <b>NOT</b> just <b>ANY</b> token</span>
                        </div>
                        <div className="any-paragraph hero-info-block">
                            <p>
                                Inspired by the works of <a href="https://en.wikipedia.org/wiki/Arnold_Harberger" target="_blank" rel="noopener noreferrer" style={{ color: "#48d8b5" }}>Arnold Harberger</a> and through the writings of <a href="https://www.amazon.com/Radical-Markets-Uprooting-Capitalism-Democracy/dp/0691177503" target="_blank" rel="noopener noreferrer" style={{ color: "#48d8b5" }}>Radical Markets</a>, <b><span style={{ textDecoration: "line-through" }}>Always</span> Never Yours</b> is an experimental game that aims to demonstrate a balance of ownership between public and private property. This economic policy, also known as Harberger Taxes, enforces citizens to value their own assets and pay a self-assessed tax based on that value. At ANY point in time, anyone else can force the sale of an asset at the currently listed price. This Non-Fungible Token is eternally bound by those rules on the Ethereum blockchain.
                            </p>
                        </div>
                    </div>
                </div>
                <Hero />
                <FAQNew />
            </div>
        );
    }
};

export default HomeNew;
