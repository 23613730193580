import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

export const convertToEth = (value, decimals = 4) => {
  if (!value) return;
  return parseFloat(ethers.utils.formatEther(value)).toFixed(decimals);
};

// export const convertToWei = (value) => {
//   if (!value) return;
//   const priceEth = value.toString();
//   const priceWei = ethers.utils.parseUnits(priceEth, 'ether');
//   return BigNumber(priceWei.toString()).toFixed();
// };

// Used with the <CurrencyInput /> component
export const convertCurrencyToWei = (value) => {
  if (!value) return;
  const priceEth = value.toString().split('Ξ ')[1];
  const priceWei = ethers.utils.parseUnits(priceEth, 'ether');
  return BigNumber(priceWei.toString()).toFixed();
};

export const minifyHash = (address) => {
  if (!address) return;
  const hashStart = address.substring(0, 6);
  const hashEnd = address.substring(address.length - 4, address.length);
  return `${hashStart}...${hashEnd}`;
};
