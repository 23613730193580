import cn from 'classnames';
import React, { Component } from 'react';
import styles from './Hero.module.sass';
import Item from './Item';

const items = [
  {
    question: `What is this?`,
    answer:   `This is the first song sold under a Harberger Tax model through a smart contract, ever.
<br /> <br />
The Jonathan Mann x BeetsDAO Harberger Tax Experiment builds on exploratory ideas of public arts ownership originally innovated by “This Artwork is Always on Sale” by Simon de la Rouviere.
<br /> <br />
In this iteration, we expand on the desired goal to create an environment where an artist earns income in perpetuity for the art they create while building mechanisms that keep owners from holding the asset forever if others want and value it more.
`,
  },
  {
    question: `How does it work?`,
    answer: `Instead of waiting for it to be put on sale, anyone can buy the song at any point if they are willing to pay the current listing price, which is a mandatory reference that the owner needs to set and pay a Harberger Tax on.
<br /> <br />
To prevent a sale from happening, the owner can raise this listing price at any point. They’ll keep the asset as long as they pay the 36.5% yearly (in daily installments) tax on it.
`,
  },
  {
    question: `How does it gain in value?`,
    answer: `That is the experiment. Anyone can buy the asset from the current owner at the price the owner sets at any time. 
<br /> <br />
As the owner you have to make a choice. Price it too low? Someone will buy it and reprice it. Price it too high? Nobody will buy it. You’re stuck with it, and you’re paying the tax!
<br /> <br />
Of course, you can always lower the price, and you can add value to the asset so it appreciates, for the public and private good.`,
  },
  {
    question: `So what is the art?`,
    answer: `Jonathan Mann remixed BeetsDAO’s original Eulerbeats Enigma LP 17, from a collection of the first fully on-chain generative music project, and made a song over it.
<br /> <br />
EB introduced the concept of a bonding curve for musical NFTs. This song mutates it now, to introduce the concept of a Harberger Tax.`,
  },
  {
    question: `How do I get it?`,
    answer: `Initially, this original piece will be auctioned for 24hs on this site, starting on September  30th at 8PM EST/12AM GMT. 
<br /> <br />
The winner will have the right to own this historic piece for the first time (that’s something that will live forever on the blockchain) and price it for resale under the Harberger Tax model. If you win - don’t forget - you have to pay the tax!
<br /> <br />
After the auction you can purchase the song from the current holder at any time.`,
  },
  {
    question: `How is it priced?`,
    answer: `An auction will be run to decide the first owner of the piece. 
    The highest bid will win the NFT. They’ll then have 24 hours to set their 
    sale price and pay their taxes accordingly for as long as they want. At any 
    point they could top up tax payments so as to extend their ownership in time. 
    They also can relist the asset for a higher or lower price, and tax amounts 
    would be updated accordingly (36.5% yearly of the listing price).`,
  },
  {
    question: `Is there a seller fee?`,
    answer: `The owner of the piece will get 90% of a resale price, while 10% is the royalty fee, split between the creator and the DAO, owner of the original EB track’s rights.`,
  },
  {
    question: `Who is Jonathan Mann?`,
    answer: `American singer-songwriter, best known for creating and publishing a new song and video each day since January 2009. Songaday: a project just a few months younger than Bitcoin.`,
  },
  {
    question: `Who is BeetsDAO?`,
    answer: `BeetsDAO is a Decentralized Autonomous Organization originally created out of the EulerBeats discord to bid on the EB Enigma auction in March 2021. The DAO won 4 of the originals and has been the largest OG since. It hosts a global collective of members working on innovative projects around the intersection of music + art & NFTs. And it’s only getting started on its journey.`,
  },
  {
    question: `What is a foreclosure?`,
    answer: `A foreclosure happens when the holder, after buying the NFT, fails to pay their daily taxes to retain its ownership. If tax-paid time expires, the NFT is transferred back to the creator, who will list it again.`,
  },
  {
    question: `How do I buy it?`,
    answer: `If you wish to purchase the song you can connect your wallet, click the buy now button and pay the price of the asset to the seller.
<br /> <br />
You then have 24 hours to list it and to pay your taxes for the period you choose to, based on a 36.5% (yearly) of your list price.
<br /> <br />
There is a minimum deposit of 10% of that list price and it will cover 100 days of tax. But you can deposit as much as you want to be covered for a longer period of time.
`,
  },
  {
    question: `Can I change the price at any point, while I own it?`,
    answer: `Yes. You can add to your deposit of pre-paid taxes at any point, as many times and as much as you want.`,
  },
  {
    question: `Will I be refunded the pre-paid taxes in case I sell?`,
    answer: `Yes, you will be refunded all the daily pre-paid taxes not consumed at the moment of selling (all remaining days).`,
  },
  {
    question: `What happens to my pre-paid period when I change the listing price?`,
    answer: `Yes, you will be refunded all the daily pre-paid taxes not consumed at the moment of selling (all remaining days).`,
  },
  {
    question: `Will I be refunded the pre-paid taxes in case I sell?`,
    answer: `Yes, you will be refunded all the daily pre-paid taxes not consumed at the moment of selling (all remaining days).`,
  },
  {
    question: `What happens to my pre-paid period when I change the listing price?`,
    answer: `The calendar will be updated, automatically and proportionally, to the new listing price. If it’s higher than before, your remaining tax deposit will cover fewer days. If it’s lower, it’ll cover a longer period. You’ll see it reflected in the calendar.
<br /><br />
The 36.5% annual tax rate stays always the same, and it always relates to the current listing price you set.
`,
  },
  {
    question: `What if I want to sell it?`,
    answer: `The asset can be sold, and you’ll get the resale price, minus the royalty % that goes to the artist -Jonathan Mann- and BeetsDAO, holder of the rights of the Original EB Enigma LP that’s the base of this remix. (5% JM, 5% BD, 90% owner).
<br /><br />
The more value the property accrues, the more it will serve as a public good (for instance, educating the public about the possibilities of different economic models such as the Harberger Tax), and this will in turn benefit the private owner of the asset, who can use it, grow it, or sell it.`,
  },
  {
    question: `What happens to my pre-paid period when I change the listing price?`,
    answer: `The calendar will be updated, automatically and proportionally, to the new listing price. If it’s higher than before, your remaining tax deposit will cover fewer days. If it’s lower, it’ll cover a longer period. You’ll see it reflected in the calendar.
<br /><br />
The 36.5% annual tax rate stays always the same, and it always relates to the current listing price you set.
`,
  },
  {
    question: `Will my name be listed as the owner of the asset?`,
    answer: `It will, as it is ENS compatible. Otherwise your wallet address will always show historical ownership.`,
  },
];

items.map(i => i.isVisible = false);

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: items,
    };
  }

  setVisible(index) {
    this.state.questions[index].isVisible = !this.state.questions[index].isVisible;

    this.setState({ questions: this.state.questions })
  };

  toggleAll() {
    this.state.questions = this.state.questions.map(q => {
      q.isVisible = !q.isVisible;
      return q;
    });

    this.setState({ questions: this.state.questions })
  }

  render() {
    return (
      <div className="mt-5">
        <div className={cn('container', styles.container)}>
          <div>
          <h3 id={"faq"} className={cn("", styles.faq)}>FAQ</h3>
          <button onClick={() => this.toggleAll()} className={cn("button-stroke", styles.toggleFaqButton)}>Toggle All</button>
          </div>
          {/*<p className="mb-5">Content Here</p>*/}
          <div className={styles.row}>
            <div className={styles.col}>
              {items.map((item, index) => (
                <span onClick={() => this.setVisible(index)}>
                  <Item
                    className={styles.item}
                    question={item.question}
                    answer={item.answer}
                    key={index}
                    isVisible={item.isVisible}
                  />
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default FAQ;
