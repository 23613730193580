import cn from 'classnames';
import React, { Component } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { convertCurrencyToWei, convertToEth } from '../../dapp/utils/helpers';
import TaxCalculator from '../TaxCalculator';
import styles from './Metadata.module.sass';

class Metadata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foreclosure: this.props.foreclosure,
    };

    this.timeRemaining = this.timeRemaining.bind(this);
  }

  componentDidMount() {
    this.timeRemaining(this.props.assetForeclosure);
    this.interval = setInterval(
      () => this.timeRemaining(this.props.assetForeclosure),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  timeRemaining = (timestamp) => {
    if (!timestamp) return;

    var current = Math.floor(Date.now() / 1000);
    var remaining = timestamp - current;
    var days = Math.floor(remaining / (3600 * 24));
    var hrs = Math.floor((remaining % (3600 * 24)) / 3600);
    var mins = Math.floor((remaining % 3600) / 60);
    var secs = Math.floor(remaining % 60);

    if (current >= timestamp) {
      this.setState({ foreclosure: true });
    } else {
      this.setState({ days, hrs, mins, secs });
    }
  };

  listAsset = (event) => {
    event.preventDefault();
    var value = convertCurrencyToWei(this.listing.value);
    value = value === undefined ? 0 : value;
    this.props.listAsset(this.props.tokenId, value);
  };

  render() {
    const assetPrice = convertToEth(this.props.assetPrice, 2);
    const assetTaxAmount = convertToEth(this.props.assetTaxAmount, 2);
    const foreclosure = this.state.foreclosure;

    const {
      ownerAddress,
      selectedAddress,
      tokenId,
      contractAddress,
    } = this.props;

    const assetNotForSale = parseFloat(assetPrice) === 0;

    let buyButtonText = 'Buy Now';
    if (assetNotForSale) {
      buyButtonText = 'Asset Not For Sale';
    }
    const isOwner = selectedAddress && ownerAddress === selectedAddress;

    return (
      <div>
        <div className={styles.wrap}>
          <div className={styles.info}>Current Price</div>
          <div className={styles.number}>Ξ {assetPrice}</div>
          {selectedAddress && ownerAddress !== selectedAddress && (
            <div className="mt-4">
              <button
                className={cn('button', styles.button)}
                disabled={assetNotForSale}
                title="Purchase Asset"
                onClick={(e) =>
                  this.props.buyAsset(tokenId, this.props.assetPrice)
                }
              >
                {buyButtonText}
              </button>
            </div>
          )}

          {selectedAddress &&
            ownerAddress === selectedAddress && (
              <div className="mt-4">
                <form onSubmit={this.listAsset}>
                  <CurrencyInput
                    className={styles.currencyInput}
                    prefix="Ξ "
                    decimalsLimit={4}
                    placeholder="Ξ Set price here"
                    ref={(input) => {
                      this.listing = input;
                    }}
                  />
                  <div className="mt-3">
                    <button
                      className={cn('button', styles.button)}
                      title="Offer Asset for Sale"
                      type="submit"
                    >
                      {isOwner && assetPrice > 0 ? "Update Price" : "List Asset For Sale"}
                    </button>
                  </div>
                </form>
              </div>
            )}

          {/*<div className={cn('mt-2 mb-5', styles.price)}>
            Min Tax Deposit: <b>Ξ {assetTaxAmount}</b>
          </div>*/}
          <div className={cn(styles.info, styles.infoSpace)}>
            Asset Foreclosure
          </div>
          <div className={styles.number}>
            {!foreclosure ? (
              <b className="time-remaining" style={{ color: "#c074ff" }}>
                {this.state.days} days, {this.state.hrs} hrs, {this.state.mins}{' '}
                mins, {this.state.secs} secs
              </b>
            ) : (
              <b className="time-expired" style={{ color: "#c074ff" }}>TIME EXPIRED</b>
            )}
          </div>
          <div className="mt-4">
            <TaxCalculator
              assetForeclosure={this.props.assetForeclosure}
              assetPrice={this.props.assetPrice}
              assetTaxAmount={this.props.assetTaxAmount}
              assetTotalDeposit={this.props.assetTotalDeposit}
              baseInterval={this.props.baseInterval}
              baseTaxValue={this.props.baseTaxValue}
              depositTax={this.props.depositTax}
              ownerAddress={this.props.ownerAddress}
              selectedAddress={this.props.selectedAddress}
              selectedBalance={this.props.selectedBalance}
              showPayTaxesNotification={this.props.showPayTaxesNotification}
              taxPercentage={this.props.taxPercentage}
              tokenId={tokenId}
              transactionPending={this.props.transactionPending}
              transactionError={this.props.transactionError}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Metadata;
