import cn from 'classnames';
import { ethers } from 'ethers';
import React from 'react';
import styles from './AuctionControls.module.sass';

function index({ dapp, auctionStatus, reserveAuction }) {
  const asset = dapp.state.assets[0];

  let creatorAddress;
  if (asset) {
    creatorAddress = ethers.utils.getAddress(asset.creator);
  }
  const curatorAddress = dapp.state.adminAddress;
  const selectedAddress = dapp.state.selectedAddress;
  const tokenId = asset?.tokenId;

  const auctionIsFinished = auctionStatus === 'Finished';

  const auctionId = reserveAuction ? reserveAuction['id'] : null;

  return (
    <div>
      {(selectedAddress === creatorAddress || selectedAddress === curatorAddress) && (auctionIsFinished || !auctionId) && (
        <div className="mt-4 text-center">
          <button
            className={cn('button', styles.button)}
            onClick={(e) => {
              dapp.createAuction(curatorAddress, tokenId);
            }}
          >
            Create Auction
          </button>
        </div>
      )}
      {selectedAddress === curatorAddress && auctionStatus === 'Pending' && (
        <div className="mt-4 text-center">
          <button
            className={cn('button', styles.button)}
            onClick={(e) => {
              dapp.beginAuction(auctionId);
            }}
          >
            Begin Auction
          </button>
        </div>
      )}
      {(selectedAddress === creatorAddress ||
        selectedAddress === curatorAddress) &&
        auctionStatus === 'Active' && (
          <div className="mt-4 text-center">
            <p>
              <button
                className={cn('button', styles.button)}
                onClick={(e) => {
                  dapp.cancelAuction(auctionId);
                }}
              >
                Cancel Auction
              </button>
            </p>
            <p className="mt-3">
              <button
                className={cn('button-stroke', styles.button)}
                onClick={(e) => {
                  dapp.endAuction(auctionId);
                }}
              >
                End Auction
              </button>
            </p>
          </div>
        )}
    </div>
  );
}

export default index;
