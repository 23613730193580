import React from 'react';
import CTA from './CTA';
import FAQ from './FAQ';
import Hero from './Hero';

const Home = () => {
  return (
    <>
      <Hero />
      <CTA />
      <FAQ />
      {/* <Selection /> */}
      {/* <Popular /> */}
      {/* <HotBid classSection="section" /> */}
      {/* <Collections /> */}
      {/* <Discover /> */}
      {/* <Description /> */}
    </>
  );
};

export default Home;
