import React from 'react';
import styles from './VideoPlayer.module.sass';
import {Spinner} from "react-bootstrap";

const VideoPlayer = ({ className, item }) => {
  if (item === undefined || item.loadingToken || item.loadingMetadata) {
      return <Spinner />
  }
  return (
    <div>
      <div className={styles.videoWrapper}>
        <div className={styles.videoPadding}></div>
        <video
          className={styles.video}
          poster={item.tokenMediaURL}
          src={item.tokenVideoURL}
          controls
          playsInline
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
