import cn from 'classnames';
import React, { useContext } from 'react';
import { PrintList } from '../../components/EulerBeat/PrintList';
import { DappStateContext } from '../../dapp/context/dappStateContext';
import styles from './EulerBeats.module.sass';

const EulerBeats = () => {
  const dapp = useContext(DappStateContext);
  if (window.ethereum.networkVersion != 1) {
    return <div>Please switch to mainnet.</div>
  }
  return (
    <div>
      <div className={cn('container', styles.container)}>
        <div className={styles.top}>
          {dapp && dapp.EBcontract ? (
            <div className="mt-4">
              <PrintList
                mintPrint={(originalTokenId, price) =>
                  dapp.mintPrint(originalTokenId, price)
                }
                getTrackSupply={(originalTokenId) =>
                  dapp.getTrackSupply(originalTokenId)
                }
                getTrackPrice={(printSupply) => dapp.getTrackPrice(printSupply)}
              />
            </div>
          ) : (
            <div className="mt-4">Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EulerBeats;
