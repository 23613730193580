import cn from 'classnames';
import React, { useState } from 'react';
import Auction from '../../components/Auction';
import Provenance from '../../components/Provenance';
import Control from './Control';
import styles from './Item.module.sass';
import Options from './Options';
import Users from './Users';

const navLinks = ['Info', 'Owners', 'History', 'Bids'];

const categories = [
  {
    category: 'black',
    content: 'art',
  },
  {
    category: 'purple',
    content: 'unlockable',
  },
];

const users = [
  {
    name: 'Raquel Will',
    position: 'Owner',
    avatar: '/images/content/avatar-2.jpg',
    reward: '/images/content/reward-1.svg',
  },
  {
    name: 'Selina Mayert',
    position: 'Creator',
    avatar: '/images/content/avatar-1.jpg',
  },
];

const Item = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div className={cn('section', styles.section)}>
        <div className={cn('container', styles.container)}>
          <div className={styles.bg}>
            <div className={styles.preview}>
              <div className={styles.categories}>
                {categories.map((x, index) => (
                  <div
                    className={cn(
                      { 'status-black': x.category === 'black' },
                      { 'status-purple': x.category === 'purple' },
                      styles.category
                    )}
                    key={index}
                  >
                    {x.content}
                  </div>
                ))}
              </div>
              <img
                srcSet="/images/content/item-pic@2x.jpg 2x"
                src="/images/content/item-pic.jpg"
                alt="Item"
              />
            </div>
            <Options className={styles.options} />
          </div>
          <div className={styles.details}>
            <h1 className={cn('h3', styles.title)}>The amazing art</h1>
            <div className={styles.cost}>
              <div className={cn('status-stroke-green', styles.price)}>
                2.5 ETH
              </div>
              <div className={cn('status-stroke-black', styles.price)}>
                $4,429.87
              </div>
              {/* <div className={styles.counter}>10 in stock</div> */}
            </div>
            {/* <div className={styles.info}>
              This NFT Card will give you Access to Special Airdrops. To learn
              more about UI8 please visit{" "}
              <a
                href="https://ui8.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ui8.net
              </a>
            </div> */}
            <div className={styles.nav}>
              {navLinks.map((x, index) => (
                <button
                  className={cn(
                    { [styles.active]: index === activeIndex },
                    styles.link
                  )}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            {activeIndex === 0 && (
              <Users className={styles.users} items={users} />
            )}
            {activeIndex === 1 && <div>Owners</div>}
            {activeIndex === 2 && <Provenance />}
            {activeIndex === 3 && <Auction />}
            <Control className={styles.control} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
