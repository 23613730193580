import React, { useContext } from 'react';
import { DappStateContext } from '../../dapp/context/dappStateContext';
import Icon from '../Icon';
import styles from './Alert.module.sass';

function Alert({ message, size }) {
  const dapp = useContext(DappStateContext);
  return (
    <div className={size === 'small' ? styles.alertSmall : styles.alert}>
      {message}
      <button
        className={styles.close}
        onClick={() => {
          dapp._dismissNetworkError();
          dapp._dismissTransactionError();
        }}
      >
        <Icon name="close" size="14" />
      </button>
    </div>
  );
}

export default Alert;
