import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";

const Footers = () => {
  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.foot}>
          <div className={cn("py-2", styles.copyright)}>
            Copyright Ⓒ 2022 Beets Holdings LLC. All rights reserved
          </div>
          <div className={cn("py-2", styles.copyright)}>
            <a className="px-2" href="https://etherscan.io/address/0x9982e0D8d59d36a729154d99Cf21D384f6D53bCC" target="_blank" rel="noopener noreferrer">
              <img src="etherscan.png" style={{ width: "40px", height: "40px" }} title="Etherscan"/>
            </a>
            <a className="px-2" href="https://discord.gg/pYUKQxvX" target="_blank" rel="noopener noreferrer">
              <img src="discord.svg" style={{ width: "30px", height: "30px" }} title="Discord"/>
            </a>
          </div>
        </div>
      </div>
      <Theme />
    </footer>
  );
};

export default Footers;
