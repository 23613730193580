import cn from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OPEN_SEA_BASE_URI } from '../../dapp/utils/constants';
import {
  convertCurrencyToWei,
  convertToEth,
  minifyHash,
} from '../../dapp/utils/helpers';
import Alert from '../Alert';
import Modal from '../Modal';
import styles from './TaxCalculator.module.sass';

class TaxCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      toggleHeader: true,
      visibleModalTaxCalculator: false,
    };

    this.calculateTax = this.calculateTax.bind(this);
    this.depositTax = this.depositTax.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Watches for the transaction status to be "Pending" before closing the modal
    if (
      prevProps.transactionPending !== this.props.transactionPending &&
      this.props.transactionPending === true
    ) {
      this.hideVisibleModalTaxCalculator();
    }
  }

  handleToggle = () => {
    this.setState({
      estimatedTax: undefined,
      selectedDate: new Date(),
      toggleHeader: !this.state.toggleHeader,
    });
  };

  calculateTax = (date) => {
    this.setState({ selectedDate: new Date(date) });

    const foreclosure = parseFloat(this.props.assetForeclosure);
    const baseInterval = parseFloat(this.props.baseInterval);
    const baseTaxValue = convertToEth(this.props.baseTaxValue);

    const currentTime = Math.floor(new Date() / 1000);
    const selectedTime = Math.floor(new Date(date) / 1000);

    var timeRemaining = foreclosure - currentTime;
    if (timeRemaining < 0) timeRemaining = 0;

    const estimatedTime = selectedTime - currentTime - timeRemaining;
    var estimatedTax = (estimatedTime / baseInterval) * baseTaxValue;
    if (estimatedTax < 0) estimatedTax = 0;

    this.setState({ estimatedTax: estimatedTax.toFixed(4) });
  };

  depositTax = (event) => {
    event.preventDefault();
    const value = convertCurrencyToWei(this.deposit.value);
    this.props.depositTax(this.props.tokenId, value);
  };

  formatTime = (unixTime) => {
    if (!unixTime) return;
    const localtz = moment.tz.guess();
    return moment.unix(unixTime).tz(localtz).format('MMMM Do, YYYY [@] h:mm a');
  };

  showVisibleModalTaxCalculator = () => {
    this.setState({
      visibleModalTaxCalculator: true,
    });
  };

  hideVisibleModalTaxCalculator = () => {
    this.setState({
      visibleModalTaxCalculator: false,
    });
  };

  render() {
    const assetForeclosure = this.props.assetForeclosure;
    const assetPrice = this.props.assetPrice;
    const assetTaxAmount = parseFloat(convertToEth(this.props.assetTaxAmount));
    const assetTotalDeposit = parseFloat(
      convertToEth(this.props.assetTotalDeposit)
    );
    const baseTaxValue = parseFloat(convertToEth(this.props.baseTaxValue));
    const baseInterval = this.props.baseInterval;
    const estimatedTax = this.state.estimatedTax;
    const ownerAddress = this.props.ownerAddress;
    const selectedAddress = this.props.selectedAddress;
    const selectedBalance = parseFloat(
      convertToEth(this.props.selectedBalance, 2)
    );
    const selectedDate = this.state.selectedDate;
    const taxPercentage = this.props.taxPercentage;

    const error = this.props.transactionError;

    const isOwner = selectedAddress && ownerAddress === selectedAddress;

    return (
      <div className={styles.wrap}>
        <button
          className={cn('button button-small button-stroke', styles.button)}
          onClick={this.showVisibleModalTaxCalculator}
        >
          {isOwner ? "Pay Taxes" : "Tax Calculator"}
        </button>

        {isOwner && this.props.showPayTaxesNotification && (
          <BootstrapAlert variant="info" className={styles.alert}>
            You've listed the asset for sale, be sure to pay your initial tax!
          </BootstrapAlert>
        )}

        <Modal
          visible={this.state.visibleModalTaxCalculator}
          onClose={this.hideVisibleModalTaxCalculator}
        >
          <h3>Tax Calculator</h3>
          <div className={styles.inner}>
            {selectedAddress && <p>Wallet Balance: <b style={{ color: "#48d8b5" }}>Ξ {selectedBalance}</b></p>}
            <div className="mt-4">
              <div className={styles.datepickerWrapper}>
                <DatePicker
                  className={styles.input}
                  selected={selectedDate}
                  minDate={moment().toDate()}
                  onChange={(date) => this.calculateTax(date)}
                />
              </div>
              <p>
                The minimum tax deposit can be calculated by applying a fixed
                percentage of <b style= {{ color: "#c074ff" }}>{taxPercentage}%</b> to the current listing
                price. For every <b style= {{ color: "#c074ff" }}>{baseTaxValue} Ξ</b> that is pre-paid in
                taxes, the clock will extend for an additional{' '}
                <b style= {{ color: "#c074ff" }}>{baseInterval / 3600} hours</b>. You can adjust the calendar
                to estimate the total amount of taxes that would need to be deposited for the
                selected date.
              </p>
              {assetPrice <= 0 && assetTotalDeposit <= 0 && (
                <p>
                  The current owner of this asset must first set a sale price
                  before a foreclosure begins on:
                  <br/>
                  <b style= {{ color: "#c074ff" }}>{this.formatTime(assetForeclosure)}</b>
                </p>
              )}
              {assetPrice > 0 && assetTotalDeposit <= 0 && (
                <p>
                  With a current sale price of{' '}
                  <b style= {{ color: "#c074ff" }}>{parseFloat(convertToEth(assetPrice))} Ξ</b>, the owner of
                  this asset must make a minimum deposit of at least{' '}
                  <b style= {{ color: "#c074ff" }}>{assetTaxAmount} Ξ</b> in taxes before a foreclosure begins
                  on:
                  <br/>
                  <b style= {{ color: "#c074ff" }}>{this.formatTime(assetForeclosure)}</b>
                </p>
              )}
              {assetPrice > 0 && assetTotalDeposit > 0 && (
                <p>
                  A foreclosure on this asset is set to begin on:
                  <br/>
                  <b style= {{ color: "#c074ff" }}>{this.formatTime(assetForeclosure)}</b>
                </p>
              )}
            </div>
            <h5 className="mb-4">
              Owner:{' '}
              <a
                href={OPEN_SEA_BASE_URI + ownerAddress}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#48d8b5" }}
              >
                {minifyHash(ownerAddress)}
              </a>
            </h5>
            {selectedAddress && (
              <form onSubmit={this.depositTax}>
                <CurrencyInput
                  className={styles.input}
                  prefix="Ξ "
                  decimalsLimit={4}
                  value={estimatedTax}
                  placeholder="Ξ 0.00"
                  ref={(input) => {
                    this.deposit = input;
                  }}
                />
                <div className="mt-3">
                  <button
                    className={cn('button', styles.button)}
                    disabled={ownerAddress !== selectedAddress}
                    title="Deposit Taxes"
                    type="submit"
                  >
                    Deposit
                  </button>
                </div>
              </form>
            )}
          </div>
          {error && (
            <div className="mt-4">
              <Alert size="small" message={error} />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default TaxCalculator;
