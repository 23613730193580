import cn from 'classnames';
import { ethers } from 'ethers';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import Alert from '../../../components/Alert';
import Auction from '../../../components/Auction';
import AuctionControls from '../../../components/AuctionControls';
import Icon from '../../../components/Icon';
import Metadata from '../../../components/Metadata';
import MintAsset from '../../../components/MintAsset';
import Ownership from '../../../components/Ownership';
import Tabs from '../../../components/Tabs';
// import TaxCalculator from '../../../components/TaxCalculator';
import VideoPlayer from '../../../components/VideoPlayer';
import useAsset from '../../../hooks/useAsset';
import useAuctionData from '../../../hooks/useAuctionData';
import styles from './Hero.module.sass';

const Hero = () => {
  const { dapp, asset } = useAsset();
  const { reserveAuction, auctionBids, auctionStatus } = useAuctionData();
  const dappAsset = dapp.state.assets[0];

  let creatorAddress;
  if (dappAsset) {
    creatorAddress = ethers.utils.getAddress(dappAsset.creator);
  }

  const tokenId = dappAsset?.tokenId;

  // If the creator of the asset is "Black Hole" then the asset hasn't been minted yet.
  // We'll show the mint asset component.
  const assetNotMinted =
    dappAsset?.creator === '0x0000000000000000000000000000000000000000';
  const isAdmin = dapp.state.adminAddress === dapp.state.selectedAddress;

  // Show notification once asset is listed, but taxes still need to be paid
  const showPayTaxesNotification =
    asset.assetPrice !== '0' && asset.assetTotalDeposit === '0';

  let creatorName = 'Jonathan Mann';
  let taxRate = '36.5% Annual';

  return (
    <>
      <div className={cn('container', styles.container)}>
        <div className={styles.headALT}>
          <h2 className={cn(styles.title)}>The Harberger Tax Song</h2>
          {/*<div className={styles.stage2}>*/}
            {/*A Radically Owned Harberger Tax Experiment by BeetsDAO*/}
            {/*and Jonathan Mann*/}
          {/*</div>*/}
          <div className={styles.stage2}>
            An economic experiment by BeetsDAO x Jonathan Mann
          </div>
        </div>
        {assetNotMinted ? (
          <div className="text-center mt-5 mb-5">
            {isAdmin ? (
              <MintAsset mintAsset={dapp.mintAsset} />
            ) : (
              <p style={{ fontSize: '18px' }}>
                Asset not minted. Please have the Administrator mint the asset.
              </p>
            )}
          </div>
        ) : (
          <div>
            {asset?.loadingToken && (
              <div className="text-center mb-5 mt-5">
                <Spinner animation="border" role="status" />
                <h3 className="mt-3">LOADING</h3>
              </div>
            )}
            {asset && !asset.loadingToken && (
              <div className={styles.wrapper}>
                {/* <div className={cn('mb-4 mt-5', styles.row)}>
                  <div className={styles.videoColumn}></div>
                  <div className={styles.details}>
                    {auctionStatus === 'Finished' && (
                      <TaxCalculator
                        assetForeclosure={asset.assetForeclosure}
                        assetPrice={asset.assetPrice}
                        assetTaxAmount={asset.assetTaxAmount}
                        assetTotalDeposit={asset.assetTotalDeposit}
                        baseInterval={dapp.state.baseInterval}
                        baseTaxValue={asset.baseTaxValue}
                        depositTax={dapp.depositTax}
                        ownerAddress={asset.ownerAddress}
                        selectedAddress={dapp.state.selectedAddress}
                        selectedBalance={dapp.state.selectedBalance}
                        showPayTaxesNotification={showPayTaxesNotification}
                        taxPercentage={dapp.state.taxPercentage}
                        tokenId={tokenId}
                        transactionPending={dapp.state.transactionPending}
                        transactionError={dapp.state.transactionError}
                      />
                    )}
                  </div>
                </div> */}
                <div className={styles.row}>
                  <div className={styles.videoColumn}>
                    <VideoPlayer item={asset} />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.item}>
                        <div className={styles.avatar}>
                          <img
                            src="/assets/artists/1.jpg"
                            alt={asset.creator}
                          />
                        </div>
                        <div className={styles.description}>
                          <div className={styles.category}>Creator</div>
                          <div className={styles.text}>{creatorName}</div>
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.icon}>
                          <Icon name="stop" size="24" />
                        </div>
                        <div className={styles.description}>
                          <div className={styles.category}>Tax Rate</div>
                          <div className={styles.text}>{taxRate}</div>
                        </div>
                      </div>
                    </div>
                    {auctionStatus === 'Finished' ? (
                      <Metadata
                        assetTotalDeposit={asset.assetTotalDeposit}
                        baseInterval={dapp.state.baseInterval}
                        baseTaxValue={asset.baseTaxValue}
                        depositTax={dapp.depositTax}
                        selectedBalance={dapp.state.selectedBalance}
                        showPayTaxesNotification={showPayTaxesNotification}
                        taxPercentage={dapp.state.taxPercentage}
                        approvedAddress={asset.approvedAddress}
                        assetForeclosure={asset.assetForeclosure}
                        assetPrice={asset.assetPrice}
                        assetTaxAmount={asset.assetTaxAmount}
                        buyAsset={dapp.buyAsset}
                        contractAddress={dapp.state.contractAddress}
                        listAsset={dapp.listAsset}
                        foreclosure={asset.foreclosure}
                        ownerAddress={asset.ownerAddress}
                        selectedAddress={dapp.state.selectedAddress}
                        setApproval={dapp.setApproval}
                        tokenId={tokenId}
                        transactionPending={dapp.state.transactionPending}
                        transactionError={dapp.state.transactionError}
                      />
                    ) : (
                      <Auction
                        dapp={dapp}
                        auctionStatus={auctionStatus}
                        auctionBids={auctionBids}
                        reserveAuction={reserveAuction}
                        transactionPending={dapp.state.transactionPending}
                      />
                    )}
                    {!dapp.state.selectedAddress && (
                      <div className="text-center">
                        <p style={{ fontSize: 16 }}>
                          Please Connect Your Wallet
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {dapp.state.transactionError && (
                  <div className="mt-4">
                    <Alert size="small" message={dapp.state.transactionError} />
                  </div>
                )}
                <div className="mt-5 mb-5">
                  <Tabs
                    dapp={dapp}
                    auctionBids={auctionBids}
                    auction={reserveAuction}
                  />
                </div>

                <div className="mt-5 mb-5">
                  <AuctionControls
                    dapp={dapp}
                    auctionStatus={auctionStatus}
                    reserveAuction={reserveAuction}
                  />
                </div>

                <div className="mt-5 mb-5">
                  <Ownership
                    adminAddress={dapp.state.adminAddress}
                    creatorAddress={creatorAddress}
                    reclaimAsset={dapp.reclaimAsset}
                    selectedAddress={dapp.state.selectedAddress}
                    tokenId={tokenId}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Hero;
