import React, {useState} from "react";

export function AlwaysNeverTitle() {
    const [alwaysStrike, setAlwaysStrike] = useState(true);
    const [neverStrike, setNeverStrike] = useState(false);

    const alwaysMouseEnter = () => {
        setAlwaysStrike(true);
        setNeverStrike(false);
    };

    const neverMouseEnter = () => {
        setAlwaysStrike(false);
        setNeverStrike(true);
    };

    return (
        <span>
            <span data-w-id="08265542-8bc3-872b-0618-4933f9d0d05c" onMouseEnter={alwaysMouseEnter} className={alwaysStrike ? "always-strike": "always-default"}>Always</span>
            <span> </span><span data-w-id="aafadb33-e18e-7f70-1760-96b25a0bb6dc" onMouseEnter={neverMouseEnter} className={neverStrike ? "never-strike" : "never-default"}>Never</span> Yours
        </span>
    );
}