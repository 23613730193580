import cn from 'classnames';
import React, { Component } from 'react';
import styles from './Ownership.module.sass';

class Ownership extends Component {
  render() {
    const adminAddress = this.props.adminAddress;
    const creatorAddress = this.props.creatorAddress;
    const selectedAddress = this.props.selectedAddress;
    const tokenId = this.props.tokenId;

    return (
      <>
        {(selectedAddress === adminAddress ||
          selectedAddress === creatorAddress) && (
          <div className="text-center mt-4">
            <button
              className={cn('button', styles.button)}
              type="button"
              title="Perform Asset Foreclosure"
              onClick={(e) => {
                this.props.reclaimAsset(tokenId);
              }}
            >
              Reclaim Asset
            </button>
          </div>
        )}
      </>
    );
  }
}

export default Ownership;
