import cn from 'classnames';
import React, { useState } from 'react';
import styles from './Item.module.sass';

const Preview = ({ className, question, answer, isVisible }) => {
  // const [visible, setVisible] = useState(false);

  return (
    <div className={cn(className, styles.item, { [styles.active]: isVisible })}>
      <div className={styles.head}>
        {question}
      </div>
      <div className={styles.body}>
        <div className={styles.content} dangerouslySetInnerHTML={{__html: answer}} />
        {/*<button className={cn('button-stroke button-small', styles.button)}>*/}
          {/*Learn more*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export default Preview;
