import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useLocation, withRouter } from 'react-router-dom';
import { ETHERSCAN_TX_URI } from '../../dapp/utils/constants';
import { minifyHash } from '../../dapp/utils/helpers';
import Alert from '../Alert';
import Footer from '../Footer';
import Header from '../Header';
import styles from './Page.module.sass';

const Page = ({ dapp, children }) => {
  const { state } = dapp;

  const { pathname } = useLocation();

  const [showPendingToast, setShowPendingToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  // Pending Transaction Notification
  useEffect(() => {
    if (state.transactionPending) {
      setShowPendingToast(true);
    }
  }, [state.transactionPending]);

  // Successful Transaction Notification
  useEffect(() => {
    if (state.transactionSuccess) {
      setShowSuccessToast(true);
    }
  }, [state.transactionSuccess]);

  const error = state.networkError || state.transactionError;

  return (
    <div className={styles.page}>
      <div className={styles.toastWrapper}>
        {/* Pending Notification */}
        <Toast
          className={styles.toast}
          onClose={() => {
            dapp._dismissTransactionPending();
            setShowPendingToast(false);
          }}
          show={showPendingToast}
          delay={7000}
          autohide
        >
          <Toast.Header
            className={styles.toastHeader}
            style={{
              background: '#fff3cd',
              color: '#856404',
            }}
          >
            <strong>Transaction Pending</strong>
          </Toast.Header>
          <Toast.Body
            style={{
              background: '#fff3cd',
              color: '#856404',
            }}
          >
            <a
              href={ETHERSCAN_TX_URI + state.transactionHash}
              style={{ color: '#856404' }}
              rel="noopener noreferrer"
              target="_blank"
            >
              View Transaction:{' '}
              <strong>{minifyHash(state.transactionHash)}</strong>
            </a>
          </Toast.Body>
        </Toast>

        {/* Success Notification */}
        <Toast
          className={styles.toast}
          onClose={() => {
            dapp._dismissTransactionSuccess();
            setShowSuccessToast(false);
          }}
          show={showSuccessToast}
          delay={7000}
          autohide
        >
          <Toast.Header
            className={styles.toastHeader}
            style={{
              background: '#d4edda',
              color: '#155724',
            }}
          >
            <strong>Transaction Successful</strong>
          </Toast.Header>
          <Toast.Body
            style={{
              background: '#d4edda',
              color: '#155724',
            }}
          >
            <a
              href={ETHERSCAN_TX_URI + state.transactionHash}
              style={{ color: '#155724' }}
              rel="noopener noreferrer"
              target="_blank"
            >
              View Transaction:{' '}
              <strong>{minifyHash(state.transactionHash)}</strong>
            </a>
          </Toast.Body>
        </Toast>
      </div>

      <Header state={state} />
      {error && <Alert message={error} />}
      <div className={styles.inner}>{children}</div>
      <Footer />
    </div>
  );
};

export default withRouter(Page);
