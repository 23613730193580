import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Page from './components/Page';
import Activity from './screens/Activity';
import ConnectWallet from './screens/ConnectWallet';
import EulerBeats from './screens/EulerBeats';
import Faq from './screens/Faq';
import Home from './screens/Home';
import HomeNew from './screens/HomeNew';
import Item from './screens/Item';
import PageList from './screens/PageList';
import Profile from './screens/Profile';
import ProfileEdit from './screens/ProfileEdit';
import Search01 from './screens/Search01';
import Search02 from './screens/Search02';
import UploadDetails from './screens/UploadDetails';
import UploadVariants from './screens/UploadVariants';
import './styles/app.sass';

function App({ dapp }) {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/old"
          render={() => (
            <Page dapp={dapp}>
              <Home />
            </Page>
          )}
        />
          <Route
              exact
              path="/"
              render={() => (
                  <Page dapp={dapp}>
                      <HomeNew />
                  </Page>
              )}
          />
        <Route
          exact
          path="/euler-beats"
          render={() => (
            <Page dapp={dapp}>
              <EulerBeats />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-variants"
          render={() => (
            <Page dapp={dapp}>
              <UploadVariants />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-details"
          render={() => (
            <Page dapp={dapp}>
              <UploadDetails />
            </Page>
          )}
        />
        <Route
          exact
          path="/connect-wallet"
          render={() => (
            <Page dapp={dapp}>
              <ConnectWallet
                connect={dapp.connectWallet}
                state={dapp.state}
                dapp={dapp}
              />
            </Page>
          )}
        />
        <Route
          exact
          path="/faq"
          render={() => (
            <Page dapp={dapp}>
              <Faq />
            </Page>
          )}
        />
        <Route
          exact
          path="/activity"
          render={() => (
            <Page dapp={dapp}>
              <Activity />
            </Page>
          )}
        />
        <Route
          exact
          path="/search01"
          render={() => (
            <Page dapp={dapp}>
              <Search01 />
            </Page>
          )}
        />
        <Route
          exact
          path="/search02"
          render={() => (
            <Page dapp={dapp}>
              <Search02 />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile"
          render={() => (
            <Page dapp={dapp}>
              <Profile />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile-edit"
          render={() => (
            <Page dapp={dapp}>
              <ProfileEdit />
            </Page>
          )}
        />
        <Route
          exact
          path="/item"
          render={() => (
            <Page dapp={dapp}>
              <Item />
            </Page>
          )}
        />
        <Route
          exact
          path="/pagelist"
          render={() => (
            <Page dapp={dapp}>
              <PageList />
            </Page>
          )}
        />
        <Route
          exact
          path="/tutorial"
          render={() => (
            <Page dapp={dapp}>
              <div className="container-fluid">
                <div className="col-1 p-5">
                  <video src="https://beets-dao-stuff.s3.amazonaws.com/Harberger+Tutorial.mov" controls></video>
                </div>
              </div>
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
