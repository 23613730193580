import { useContext, useEffect, useState } from 'react';
import { DappStateContext } from '../dapp/context/dappStateContext';
import { graphURL } from '../dapp/utils/constants';

const axios = require('axios');

const useAuctionData = () => {
  const dapp = useContext(DappStateContext);
  const [reserveAuction, setReserveAuction] = useState();

  const [auctionCurrentBids, setAuctionCurrentBids] = useState([]);
  const [auctionPreviousBids, setAuctionPreviousBids] = useState([]);

  useEffect(() => {
    const currentBidRequest = async (currentBid) => {
      if (currentBid) {
        const query = {
          query: `query reserveAuctionBid($id: ID!) {
          reserveAuctionBid(
            id: $id
          )
          {
            amount
            bidder {
              id
            }
            bidType
            createdAtTimestamp
            transactionHash
          }
        }`,
          variables: {
            id: currentBid['id'],
          },
        };

        try {
          const response = await axios.post(graphURL, query);
          const currentBid = response.data.data.reserveAuctionBid;
          setAuctionCurrentBids([currentBid]);
        } catch (err) {
          console.log(err);
        }
      }
    };

    const previousBidsRequest = async (previousBids) => {
      const getPreviousBids = async (previousBid) => {
        const query = {
          query: `query inactiveReserveAuctionBid($id: ID!) {
              inactiveReserveAuctionBid(
                id: $id
              )
              {
                amount
                bidder {
                  id
                }
                bidType
                createdAtTimestamp
                transactionHash
              }
            }`,
          variables: {
            id: previousBid['id'],
          },
        };

        try {
          const response = await axios.post(graphURL, query);
          const previousBid = response.data.data.inactiveReserveAuctionBid;
          return previousBid;
        } catch (err) {
          console.log(err);
        }
      };

      const response = await Promise.all(
        previousBids.map((previousBid) => {
          return getPreviousBids(previousBid);
        })
      );

      setAuctionPreviousBids(response);
    };

    const reserveAuctionRequest = async () => {
      const query = {
        query: `query reserveAuctions($tokenContract: ID!) {
          reserveAuctions(
            where: {
              tokenContract: $tokenContract
            })
          {
            id
            approved
            currentBid {
              id
            }
            duration
            expectedEndTimestamp
            finalizedAtTimestamp
            previousBids {
              id
            }
            reservePrice
            status
            tokenOwner {
              id
            }
            createdAtTimestamp
          }
        }`,
        variables: {
          tokenContract: dapp.state.contractAddress.toLowerCase(),
        },
      };

      try {
        const response = await axios.post(graphURL, query);
        const reserveAuction = response.data.data.reserveAuctions.reverse()[0];
        if (!reserveAuction) return;
        const currentBid = reserveAuction ? reserveAuction['currentBid'] : null;
        const previousBids = reserveAuction
          ? reserveAuction['previousBids']
          : null;
        setReserveAuction(reserveAuction);

        if (currentBid) {
          currentBidRequest(currentBid);
        }
        if (previousBids) {
          previousBidsRequest(previousBids);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (dapp.state.contractAddress) {
      reserveAuctionRequest();
    }
  }, [dapp.state.contractAddress, dapp.state.transactionSuccess]);

  return {
    reserveAuction,
    auctionBids: [...auctionCurrentBids, ...auctionPreviousBids],
    auctionStatus: reserveAuction ? reserveAuction['status'] : null,
  };
};

export default useAuctionData;
